@font-face {
  font-family: USXFont;
  src: url('./font/Judson-Regular.ttf')
}
body {
  margin: 0;
  font-family: USXFont,-apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  /* background: linear-gradient(180deg, #EAF1FF 0%, rgba(235, 244, 241, 0.00) 100%);; */
  color: #A3ADBE;
  background-color: #0D0D0D;
}
*{
  margin:0;
  padding:0;
  box-sizing:border-box;
}
ul,li{
  list-style: none;
}
a{
  text-decoration: none;
  color: #fff;
}
h1,h2,h3,h4,h5,h6{
  font-weight:400
}
a:active{
  color: #fff;
}
a:visited{
  color: #fff;
}
img{
  display: inline-block;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.wrapper{
  position: relative;
  width: 100%;
  height: 100vh;
}
/* hover 模块边框特效str */
.btn {
  cursor: pointer;
  position: relative;
  padding: 10px 20px;
  font-size: 28px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 1s;
}
.btn .nohover{
  display: block;
}
.btn .hover{
  display: none;
}

.btn::after,
.btn::before {
  content: " ";
  width: 10px;
  height: 10px;
  position: absolute;
  border: 0px solid #FF5C00;
  transition: all 0.5s;
}

.btn::after {
  top: -1px;
  left: -1px;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
}

.btn::before {
  bottom: -1px;
  right: -1px;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
}

.btn:hover {
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.btn:hover .hover{
  display: block!important;
}
.btn:hover .nohover{
  display: none!important;
}
.btn:hover::before{
  width: 100%;
  height: 100%;
  border-bottom: 5px solid #FF5C00;
  border-right: 5px solid #FF5C00;
}
.btn:hover::after {
  width: 100%;
  height: 100%;
  border-top: 5px solid #FF5C00;
  border-left: 5px solid #FF5C00;
}
/* hover 模块边框特效end */
.arco-statistic-content .arco-statistic-value{
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  color: #fff;
}
.arco-tabs-header-title{
  font-size: 16px;
}
.arco-tabs-header-nav-line .arco-tabs-header-title{
  margin: 0 20px;
}

@media (max-width: 1360px) {
  .arco-statistic-content .arco-statistic-value{
    font-size: 26px;
    line-height: 26px;
  }
  .arco-tabs-header-title{
    font-size: 14px;
  }
  .arco-tabs-header-nav-line .arco-tabs-header-title{
    margin: 0 16px;
  }
}
